<template>
	<div class="grafana-container">
		<iframe :src="grafanaUrl" class="grafana-dashboard" frameborder="0"></iframe>
		<div v-if="user.role === 'SUPER_ADMIN'">
			<BaseFormInput
				@input="handleInputIframe"
				:inputOptions="['small']"
				label="Iframe Grafana *"
				inline
			/>
			<button @click="add">ADD</button>
			<div v-if="listIframe.length > 0">
				<div ref="iframe-information" class="iframe-container"  v-for="(item,key) in listIframe" :key="key" v-html="item">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import { isEmpty } from "../../selectors/helpers/index";
import userPermission from "../../mixins/userPermission";

export default {
	mixins: [userPermission],
	computed: {
		grafanaUrl() {
			return process.env.VUE_APP_GRAFANA_ZLD_URL;
		}
	},

	data() {
		return {
			inputIframe: "",
			listIframe: []
		};
	},

	mounted() {
		this.addActivityLogGrafanaView({
			shopToId: this.$route.params.id,
			httpStatus: 200
		});
		this.$emit("loaded");

		this.$nextTick(() => this.attachIframeListeners());
	},

	methods: {
		...mapActions("activityLogAdd", {
			addActivityLogGrafanaView: "addActivityLogGrafanaView"
		}),
		handleInputIframe(value) {
			this.inputIframe = value;
		},
		add() {
			if (isEmpty(this.inputIframe)) return;
			const isBigth = this.inputIframe.includes("bigth.com");
			let replaceValue = this.inputIframe.replace(`width="450"`, ``).replace(`height="200"`, `style="width:100%;height:600px;"`).replace(`monitoring.bigcloudth.com`, `apis.bigcloudth.com`);
			if (isBigth) {
				replaceValue = this.inputIframe.replace(`width="450"`, ``).replace(`height="200"`, `style="width:100%;height:600px;"`).replace(`monitoring.bigth.com`, `apis.bigth.com`);
			}
			this.listIframe = [...this.listIframe, replaceValue];
		},

		attachIframeListeners() {
			// Delayed access to iframe
			setTimeout(() => {
				const iframeContainer = this.$refs["iframe-information"];
				iframeContainer.forEach((el) => {
					const iframe = el.querySelector("iframe");
					if (iframe) {
						const token = iframe.getAttribute("token");
						if (token) {
							iframe.onload = () => {
								this.sendTokenMassage(iframe, token);
							};
							// If the iframe is already loaded, send the message immediately
							if (iframe.contentDocument?.readyState === "complete") {
								this.sendTokenMassage(iframe, token);
							}
						}
					}
				});
			}, 1000);
		},

		sendTokenMassage(iframe, token) {
			if (iframe.contentWindow) {
				iframe.contentWindow.postMessage(
					{ type: "jwt-token", content: token },
					["https://app-uat.zplify.com", "https://app.zplify.com"]
				);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.grafana-container {
	display: flex;
	flex-direction: column;
	gap: rem(12);

	.grafana-dashboard {
		width: 100%;
		height: rem(690);
	}
}

.iframe-container {
	padding: rem(24);
	margin-top: rem(16);
	border: 1px grey solid;
}

</style>
