<template>
	<div class="main-content">
		<div class="module-list">
			<!-- Module items -->
			<div class="module-items history-container">
				<BaseLoading
					v-if="isLoading"
					class="devices-lists-loading"
				/>

				<div
					v-if="showMessageDateFilter && !isLoading"
					class="text-date-filter"
				>
					{{ showMessageDateFilter }}
				</div>

				<template v-if="!hasNoData && !isLoading">
					<div
						v-if="hasExportPermission"
						class="container-filter"
					>
						<BaseDropdown
							:value="fileType"
							:list="fileTypeOptions"
							:searchable="false"
							placeholder="Select file type "
							class="container-dropdown"
							@input="onSelectFileType"
						/>
						<BaseButton size="medium" class="custom-export-btn" @click="onClickExport">
							Export
						</BaseButton>
					</div>

					<BaseTableCustom
						class="history-table"
						:tableOptions="tableOptions"
						:loading="false"
					>
						<template v-slot:thead>
							<tr>
								<th
									v-for="({ value, ...rest }, index) in thead"
									:key="index"
									:class="rest.class"
								>
									{{ value }}
								</th>
							</tr>
						</template>
						<template v-slot:tbody>
							<template v-for="(item, index) in eOrderingHistories.data">
								<tr
									:key="index"
									class="table-row"
								>
									<td
										class="text-develiery view-button clickable"
										@click="toggleExplain(item)">
										{{ item.deliveryDate }}
										<FontAwesomeIcon
											:class="['icon', { '-is-active': item.isExplain }]"
											:icon="['far', 'angle-down']"
										/>
									</td>
									<td>
										{{ item.orderNo }}
									</td>
									<td class="text-limit-length">
										{{ item.shipTo }}
									</td>
									<td>
										{{ item.product }}
									</td>
									<td>
										{{ item.shipId }}
									</td>
									<td class="align-right">
										{{ showActualAmount(item) }}

									</td>
									<td>
										<EOrderStatus :status="item.status" />
									</td>
									<!-- <td> TODO: uncomment when COA fixed
										<a v-if="item.isPdf" class="view-button clickable" @click.prevent="handleDownloadAttachment(item.orderNo)" >
											<font-awesome-icon class="icon-file" :icon="['far', 'file-alt']"/>	Download COA
										</a>
									</td> -->
									<!-- <td> TODO: uncomment when DO success
										<a class="view-button clickable" @click.prevent="handleDownloadAttachmentDo(item.orderNo)" >
											<font-awesome-icon class="icon-file" :icon="['far', 'file-alt']"/>	Download DO
										</a>
									</td> -->
								</tr>
								<template  v-if="item.isExplain" >
									<tr :key="`hidden-header-${index}`" class="hidden-row-header">
										<td class="bold">D/O No.</td>
										<td colspan="4" class="bold">Trailer ID</td>
										<td class="bold actual-amount">Order amount</td>
										<td colspan="2"></td>
									</tr>
									<tr :key="`hidden-data-${index}`" class="hidden-row-data">
										<td >{{ showDoNo(item.doNo) }}</td>
										<td colspan="4" >{{ item.trailer }}</td>
										<td class="align-right">
											{{ showAmount(item) }}
										</td>
										<td colspan="2"></td>
									</tr>
								</template>
							</template>
						</template>
					</BaseTableCustom>
				</template>

				<EmptyOrderHistory v-if="hasNoData && !isLoading"/>
			</div>
		</div>

		<BasePagination
			v-if="showPagination.lastPage > 1 && !isLoading"
			:value="showPagination.currentPage"
			:totalPage="showPagination.lastPage"
			class="module-pagination"
			@onPageChange="handlePageChange"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import EmptyOrderHistory from "@/components/eOrdering/EmptyOrderHistory.vue";
import EOrderStatus from "@/components/eOrdering/EOrderStatus.vue";

import { getUri } from "../../selectors/helpers";
import { formatCounter } from "../../selectors/helpers/formatCounter";
import { PAGE_NAME } from "../../enums/pagePermission";
import { mapUnit } from "../../selectors/helpers/mapUnit";
import { MASTERDATA_PERMISSIONS } from "../../enums/masterData";
import { STATUS_LABEL } from "../../enums/eOrdering/status";

export default {
	name: "EOrderingHistoryView",

	components: {
		BaseLoading,
		EmptyOrderHistory,
		BaseTableCustom,
		EOrderStatus
	},

	props: {
		viewType: {
			type: String,
			default: "delivery"
		}
	},

	data() {
		return {
			STATUS_LABEL,
			thead: [
				{ value: "Delivery Date", key: "", class: "bold" },
				{ value: "Order No.", key: "", class: "" },
				{ value: "Ship-to", key: "", class: "text-limit-length" },
				{ value: "Product", key: "", class: "" },
				{ value: "Tank No.", key: "", class: "" },
				{ value: "Actual amount", key: "", class: "actual-amount" },
				{ value: "Status", key: "", class: "" }
				// { value: "COA", key: "", class: "" } TODO: uncomment whene COA fixed
				// { value: "DO", key: "", class: "" } TODO: uncomment when DO success
			],
			tableOptions: [
				"is-striped is-fullwidth"
			],
			fileType: "xlsx",
			fileTypeOptions: [
				{
					label: ".csv",
					value: "csv"
				},
				{
					label: ".xlsx",
					value: "xlsx"
				}
			]
		};
	},

	computed: {
		...mapState(["eOrderingHistories", "eOrderingInfo", "user"]),

		hasNoData() {
			return this.eOrderingHistories.isEmpty;
		},

		showPagination() {
			return this.eOrderingHistories.pagination;
		},

		isLoading() {
			return this.eOrderingHistories.isLoading;
		},

		hasFilter() {
			let dateRange = {
				startDate: this.$route.query.startDate || null,
				endDate: this.$route.query.endDate || null
			};

			if (this.$route.query.filter) {
				dateRange = {
					filter: this.$route.query.filter
				};
			}

			return dateRange;
		},

		hasExportPermission() {
			return this.user.rolePermissions.includes(MASTERDATA_PERMISSIONS.EOrderingExport);
		},

		showMessageDateFilter() {
			return this.eOrderingHistories.message;
		}
	},

	created() {
		this.fetchData();
	},

	methods: {
		...mapActions("eOrderingHistories", {
			getEOrderingHistories: "getEOrderingHistories",
			toggleEOrderingHistory: "toggleEOrderingHistory"
		}),

		onSelectFileType(input) {
			this.fileType = input;
		},

		onClickExport() {
			// docs: https://bigportal.qa-api.bigth.com/doc/#api-EOrdering-GetEOrderingHistoriesExport\
			let params = {
				fileType: this.fileType
			};

			if (this.$route.query.productname) {
				params = {
					...params,
					productname: this.$route.query.productname
				};
			}

			if (this.$route.query.status) {
				params = {
					...params,
					status: this.$route.query.status
				};
			}

			if (
				this.$route.query.filter ||
				this.$route.query.startDate ||
				this.$route.query.endDate
			) {
				params = {
					...params,
					...this.hasFilter
				};
			}

			const path = `/e-ordering/histories/export`;
			const uri = getUri(path, params);
			window.location = uri.href;
		},

		async fetchData() {
			const params = {
				dateType: this.$route.query.view,
				...this.hasFilter,
				productname: this.$route.query.productname,
				status: this.$route.query.status,
				q: this.$route.query.q,
				page: this.$route.query.page
			};

			await this.getEOrderingHistories(params);
		},

		showAmount(val) {
			return `${formatCounter(val.amount)} ${mapUnit(val.device, this.eOrderingInfo.configuration)}`;
		},

		showActualAmount(val) {
			if (val.actualAmount === null || isNaN(parseFloat(val.actualAmount))) {
				return "N/A";
			}
			return `${formatCounter(val.actualAmount, 2)} ${mapUnit(val.device, this.eOrderingInfo.configuration)}`;
		},

		handlePageChange(val) {
			this.$router.push({
				name: PAGE_NAME.E_ORDERING_LIST,
				query: {
					...this.$route.query,
					page: val
				}
			});
		},

		showDoNo(val) {
			return val || "N/A";
		},

		toggleExplain(item) {
			this.toggleEOrderingHistory(item.orderNo);
		},

		handleDownloadAttachment(orderNo) {
			const path = `/e-ordering/infos/${orderNo}/pdf`;
			const uri = getUri(path, {});
			window.location = uri.href;
		},

		handleDownloadAttachmentDo(orderNo) {
			const path = `/e-ordering/infos/${orderNo}/dopdf`;
			const uri = getUri(path, {});
			window.location = uri.href;
		}
	}
};
</script>

<style lang="scss" scoped>
.main-content {
	padding: 0;

	.module-pagination {
		justify-content: flex-end;
		margin-right: rem(-6);
	}

	.empty-list {
		font-size: $font-20;
		font-weight: 600;
		margin-top: rem(32);
		text-align: center;
	}

	.history-container {
		.container-filter {
			display: flex;
			justify-content: flex-end;

			.container-dropdown {
				width: rem(138);

				&::v-deep .dropdown-value {
					background-color: $color-alabaster;
					border: 0;
					border-top-left-radius: rem(8);
					border-bottom-left-radius: rem(8);
				}
			}

			.custom-export-btn {
				border-radius: 0 $border-radius-normal $border-radius-normal 0;
			}
		}

		.history-table {
			padding-top: rem(24);

			::v-deep {
				.container-custom-main-table {
					&.is-striped {
						tbody {
							tr:not(.is-selected) {
								&:nth-child(even) {
									background-color: unset;
								}
							}

							.table-row:not(.is-selected) {
								&:nth-child(even) {
									background-color: $table-striped-row-even-background-color !important;
								}
							}
						}
					}

					.table-row {
						.view-button {
							.icon {
								margin-left: 2px;
								vertical-align: sub;
								transition: transform 0.3s;

								&.-is-active {
									transform: rotate(-180deg);
								}
							}

							.icon-file {
								margin-right: 2px;
								font-size: rem(14);
							}
						}
					}

					.hidden-row-header {
						border-top: 2px solid $color-ice;

						td {
							padding-bottom: 0;
						}
					}

					.hidden-row-data {
						td {
							padding-top: 0;
						}
					}

					.order-amount {
						width: 122px;
						text-align: right;
					}

					.actual-amount {
						text-align: right;
						width: 122px;
					}
				}
			}

			.text-develiery {
				font-weight: 600;
			}

			.text-limit-length {
				@include ellipsisOneline;

				max-width: rem(80);
			}
		}
	}
}

.text-date-filter {
	font-size: $font-16;
	color: $color-gunmetal-two;
	letter-spacing: rem(0.44);
}
</style>