<template>
	<div>
		<EmptyOrderHistory
			v-if="isEmpty"
			class="empty"
		/>
		<template v-else>
			<div class="header-container">
				<section>
					<BaseSortBy
						:value="sortByIndex"
						:options="optionSort"
						@change="handleChangeSortBy"
					/>
				</section>
				<BaseFilterExport
					mode="range"
					class="custom-filter-export"
					@onSubmit="handleSubmitExport"
				/>
			</div>
			<BaseTableCustom
				:tableOptions="tableOptions"
				:pagination="pagination"
				:loading="loading"
				:stickyHeader="true"
				@onPageChange="handlePageChange"
			>
				<template v-slot:thead>
					<tr>
						<th
							v-for="({value, className}, index) in tableData.thead"
							:key="index"
						>
							<div :class="className">
								{{ value }}
							</div>
						</th>
					</tr>
				</template>
				<template v-slot:tbody>
					<tr
						v-for="(item, index) in tableData.tbody"
						:key="index"
						:class="[{inactive: !item.isActive}]"
					>
						<td class="bold">
							{{ item.date }}
						</td>
						<td class="align-center">
							<button class="view-button btn-link" @click="handleDownloadDoPdfAttachment(item.doNoFile)">
								<FontAwesomeIcon :icon="['far', 'file-alt']" /> {{ item.doNumber }}
							</button>
						</td>
						<td class="align-right">
							{{ item.orderAmount }}
						</td>
						<td v-if="unitType === UNIT_TYPE.KG || unitType === UNIT_TYPE.NM3 || unitType === UNIT_TYPE.SM3 || unitType === UNIT_TYPE.LITER" class="align-right">
							{{ item.actualAmount }}
						</td>
						<td v-if="unitType === UNIT_TYPE.LITER" class="align-right">
							{{ item.actualAmount }}
						</td>
						<td v-if="unitType === UNIT_TYPE.NM3" class="align-right">
							{{ item.actualAmountNm3 }}
						</td>
						<td v-if="unitType === UNIT_TYPE.SM3" class="align-right">
							{{ item.actualAmountSm3 }}
						</td>
						<!-- TODO: Waiting for customer fixing COA data -->
						<!-- <td class="align-center action">
							<button v-if="item.isCoaFile" class="view-button btn-link" @click="handleDownloadCoPdfAttachment(item.doNoFile)">
								<FontAwesomeIcon :icon="['far', 'file-alt']" />
								Download COA
							</button>
						</td> -->
					</tr>
				</template>
			</BaseTableCustom>
		</template>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapState, mapActions, mapGetters } from "vuex";
import BaseTableCustom from "@/components/BaseTableCustom.vue";
import BaseSortBy from "@/components/BaseSortBy.vue";
import BaseFilterExport from "@/components/BaseFilterExport.vue";
import EmptyOrderHistory from "@/components/dashboard/modules/EmptyOrderHistory.vue";
import { UNIT_TYPE, UNIT_DISPLAY_TYPE } from "../../enums/unit";
import { getUri } from "../../selectors/helpers";

export default {
	name: "TankOrder",

	components: {
		BaseTableCustom,
		BaseSortBy,
		BaseFilterExport,
		EmptyOrderHistory
	},

	data() {
		return {
			id: this.$route.params.id,

			tableOptions: [
				"is-striped is-fullwidth is-responsive"
			],
			UNIT_TYPE,
			// Filter header table as unit type
			tableTheads: [
				{ value: "Date", className: "", unit: [UNIT_TYPE.KG, UNIT_TYPE.SM3, UNIT_TYPE.NM3, UNIT_TYPE.LITER] },
				{ value: "Order No.", className: "align-center", unit: [UNIT_TYPE.KG, UNIT_TYPE.SM3, UNIT_TYPE.NM3, UNIT_TYPE.LITER] },
				{ value: `Order amount (${UNIT_DISPLAY_TYPE.KG})`, className: "align-right", unit: [UNIT_TYPE.KG, UNIT_TYPE.SM3, UNIT_TYPE.NM3, UNIT_TYPE.LITER] },
				{ value: `Actual amount (${UNIT_DISPLAY_TYPE.KG})`, className: "align-right", unit: [UNIT_TYPE.KG, UNIT_TYPE.SM3, UNIT_TYPE.NM3, UNIT_TYPE.LITER] },
				{ value: `Actual amount (${UNIT_DISPLAY_TYPE.LITER})`, className: "align-right", unit: [UNIT_TYPE.LITER] },
				{ value: `Actual amount (${UNIT_DISPLAY_TYPE.NM3})`, className: "align-right", unit: [UNIT_TYPE.NM3] },
				{ value: `Actual amount (${UNIT_DISPLAY_TYPE.SM3})`, className: "align-right", unit: [UNIT_TYPE.SM3] }
				// TODO: Waiting for customer fixing COA data
				// { value: "COA", className: "align-center", unit: [UNIT_TYPE.KG, UNIT_TYPE.SM3, UNIT_TYPE.NM3, UNIT_TYPE.LITER] }
			],

			currentPage: this.$route.query.page || 1,
			sortByIndex: this.$route.query.sortByIndex ? Number(this.$route.query.sortByIndex) : 1,
			sortBy: this.$route.query.sort || null,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				sort: this.$route.query.sort || null
			}
		};
	},

	computed: {
		...mapState({
			response: (state) => {
				return state.tankHistory;
			}
		}),

		...mapGetters({
			getUnit: "tankInfo/getUnit"
		}),

		data() {
			return this.response.data;
		},

		pagination() {
			return this.response.pagination;
		},

		loading() {
			return this.response.isLoading;
		},

		tableData() {
			return {
				thead: this.tableTheads.filter((item) => {
					return item.unit?.includes(this.unitType);
				}),
				tbody: this.response.data
			};
		},

		isEmpty() {
			return this.response.isEmpty;
		},

		optionSort() {
			return {
				title: "Sort by",
				defaultAtIndex: this.sortByIndex,
				dropdown: [
					{
						index: 0,
						code: "date:asc",
						text: "Oldest"
					},
					{
						index: 1,
						code: "date:desc",
						text: "Newest"
					}
				],
				style: {
					position: "left"
				}
			};
		},

		unitType() {
			return this.getUnit.toLowerCase();
		}
	},

	async created() {
		this.unitValue = this.unitType;
		await this.fetchData();
		this.$emit("loaded", this.fetchData);
	},

	methods: {
		...mapActions(["getTankHistory", "clearTankHistory"]),
		...mapActions("activityLogAdd", {
			addActivityLogHistoryView: "addActivityLogHistoryView"
		}),

		fetchData() {
			return Promise.all([
				this.getTankHistory({ id: this.id, params: this.queryParams }),
				this.addActivityLogHistoryView({ httpStatus: this.response.statusCode, shopToId: this.id })
			]);
		},

		handlePageChange(page) {
			this.currentPage = page;
			this.redirectPage();
		},

		handleChangeSortBy(sortByValue) {
			this.sortByIndex = sortByValue.index;
			this.sortBy = sortByValue.code;
			this.currentPage = 1;
			this.redirectPage();
		},

		redirectPage() {
			this.query = {
				page: this.currentPage,
				...(this.sortBy ? { sort: this.sortBy } : {}),
				sortByIndex: this.sortByIndex
			};

			this.$router.push({
				...this.name,
				query: this.query
			});
		},

		handleDownloadDoPdfAttachment(doNoFile) {
			const id = this.id;

			const path = `/tanks/${id}/do-pdf?doNo=${doNoFile}`;
			const uri = getUri(path, {});
			window.location = uri.href;
		},

		handleDownloadCoPdfAttachment(doNoFile) {
			const id = this.id;

			const path = `/tanks/${id}/coa-pdf?doNo=${doNoFile}`;
			const uri = getUri(path, {});
			window.location = uri.href;
		},

		handleSubmitExport(form) {
			const id = this.id;

			const params = {
				...(
					form.fileType ? {
						type: form.fileType
					} : null
				),
				...(
					form.dateRange.start && form.dateRange.end ? {
						startDate: dayjs(form.dateRange.start).startOf("day").toISOString(),
						endDate: dayjs(form.dateRange.end).endOf("day").toISOString()
					} : null
				),
				sort: this.sortBy ? this.sortBy : "date:desc",
				unit: this.unitValue
			};
			const path = `/tanks/${id}/export-order-histories`;
			const uri = getUri(path, params);
			window.location = uri.href;
		}
	},

	destroyed() {
		this.clearTankHistory();
	}
};
</script>

<style lang="scss" scoped>
.empty {
	margin-top: $empty-order-margin-top;
}

.btn-link {
	justify-content: center;
	width: 100%;
}

.header-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: rem(24);

	label {
		@include mobile {
			display: none;
		}
	}

	@include mobile {
		background-color: $color-alabaster;
		align-items: flex-end;
		flex-direction: column;
		margin: 0 rem(-16);
		padding: rem(24) rem(16);
	}

	section {
		display: flex;
		align-items: center;
		width: 100%;

		@include mobile {
			flex-direction: column;
			margin-bottom: rem(16);
		}
	}
}

::v-deep {
	.pagination-container {
		justify-content: flex-end;
	}

	.container-filter {
		@include mobile {
			flex-direction: column;
			width: 100%;
		}

		.container-datepicker {
			@include mobile {
				margin-bottom: rem(16);
				width: 100%;
			}

			.custom-input {
				.input {
					@include mobile {
						background-color: $color-white;
						border-radius: rem(8);
					}
				}
			}
		}

		.container-dropdown {
			@include mobile {
				margin-bottom: rem(16);
				width: 100%;
			}

			.dropdown-value {
				@include mobile {
					background-color: $color-white;
					border-left: rem(1) solid $color-silver;
					border-radius: rem(8);
				}
			}
		}

		.custom-export-btn {
			@include mobile {
				border-radius: rem(8);
			}
		}
	}
}
</style>