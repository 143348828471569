import Vue from "vue";
import Vuelidate from "vuelidate";
import VCalendar from "v-calendar";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library as faLibrary } from "@fortawesome/fontawesome-svg-core";
import VModal from "vue-js-modal";
import VueGtag from "vue-gtag";
import vClickOutside from "v-click-outside";
import Raven from "raven-js";
import RavenVue from "raven-js/plugins/vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import VueCookies from "vue-cookies";
import VueCompositionAPI from "@vue/composition-api";
import FloatingVue, { Menu } from "floating-vue";

import "swiper/css/swiper.css";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "floating-vue/dist/style.css";
import BaseButton from "@/components/BaseButton.vue";
import BaseTag from "@/components/BaseTag.vue";
import BaseFormInput from "@/components/BaseFormInput.vue";
import BaseFormInputLabel from "@/components/BaseFormInputLabel.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import BaseDropdownLabel from "@/components/BaseDropdownLabel.vue";
import BaseFormInputAutoComplete from "@/components/BaseFormInputAutoComplete.vue";
import BasePagination from "@/components/BasePagination.vue";
import BaseLayout from "@/components/BaseLayout.vue";
import {
	faSearch,
	faAngleDown,
	faAngleUp,
	faAngleLeft,
	faAngleRight,
	faBuilding,
	faLaptopCode,
	faTachometer,
	faFileInvoiceDollar,
	faUsersCog,
	faChevronCircleDown,
	faEnvelope,
	faEye,
	faEyeSlash,
	faChevronCircleLeft,
	faChevronCircleRight,
	faLockAlt,
	faSortAlphaDown,
	faSortAlphaUp,
	faChartArea,
	faTimes,
	faInfoCircle,
	faChevronLeft,
	faChevronRight,
	faFileTimes,
	faPhoneAlt,
	faUser,
	faBars,
	faRedoAlt,
	faCopy,
	faChevronCircleUp,
	faClipboardUser,
	faUserFriends,
	faMegaphone,
	faCheckCircle as faCheckCircleRagular,
	faUserClock,
	faCalendar,
	faFileChartLine,
	faLaptopMedical,
	faUserChart,
	faSignIn,
	faUserPlus,
	faPhoneLaptop,
	faSignalSlash,
	faWindowClose,
	faListUl,
	faGripHorizontal,
	faClock,
	faSignOut,
	faQuestionCircle,
	faUserCircle,
	faBrowser,
	faFile,
	faSyncAlt,
	faFileCsv,
	faFileExcel,
	faFileDownload,
	faExclamationCircle as farExclamationCircle,
	faCog,
	faColumns,
	faMapMarked,
	faChartLine,
	faChartBar,
	faEdit,
	faUsers,
	faBellOn,
	faListAlt,
	faProjectDiagram,
	faChevronUp,
	faChevronDown,
	faBookmark as farBookmark,
	faWrench,
	faClipboardList,
	faPlusCircle as farPlusCircle,
	faMinusCircle as farMinusCircle,
	faExclamationTriangle as farExclamationTriangle,
	faFileAlt,
	faTrash
} from "@fortawesome/pro-regular-svg-icons";
import {
	faExclamationCircle,
	faCircle,
	faSort,
	faCheckCircle,
	faSpinner,
	faInfoCircle as faInfoCircleSolid,
	faUser as faUserSolid,
	faSortUp,
	faSortDown,
	faPlusCircle,
	faMinusCircle,
	faBookmark,
	faPhoneAlt as fasPhoneAlt,
	faCaretUp,
	faCaretDown
} from "@fortawesome/pro-solid-svg-icons";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { capitalize, currency, fullCurrency, twoDecimalComma, dateTimeFormat, dateFormat, friendlyNumber, friendlyNumberWithIntegerType, allowDashString, percentageRoundUpDown, nagativeCurrencyWithNoTApplicable } from "./filters";
import { hack100vhForMobile } from "./selectors/helpers/hack100vhForMobile";

// Styles
import "./assets/styles/app.scss";

// Font Awesome
faLibrary.add(
	faSearch,
	faAngleDown,
	faAngleUp,
	faAngleLeft,
	faAngleRight,
	faBuilding,
	faLaptopCode,
	faCopy,
	faTachometer,
	faFileInvoiceDollar,
	faUsersCog,
	faChevronCircleDown,
	faEnvelope,
	faEye,
	faEyeSlash,
	faExclamationCircle,
	faChevronCircleLeft,
	faChevronLeft,
	faChevronRight,
	faChevronCircleRight,
	faLockAlt,
	faSortAlphaUp,
	faSortAlphaDown,
	faCircle,
	faChartArea,
	faTimes,
	faFileTimes,
	faInfoCircle,
	faSort,
	faCheckCircle,
	faSpinner,
	faInfoCircleSolid,
	faPhoneAlt,
	faUser,
	faBars,
	faUserSolid,
	faChevronCircleUp,
	faClipboardUser,
	faUserFriends,
	faMegaphone,
	faRedoAlt,
	faChevronCircleUp,
	faCheckCircleRagular,
	faUserClock,
	faCalendar,
	faFileChartLine,
	faLaptopMedical,
	faUserChart,
	faSignIn,
	faUserPlus,
	faPhoneLaptop,
	faSignalSlash,
	faWindowClose,
	faListUl,
	faGripHorizontal,
	faClock,
	faSortUp,
	faSortDown,
	faSignOut,
	faQuestionCircle,
	faUserCircle,
	faBrowser,
	faFile,
	faSyncAlt,
	faFileCsv,
	faEye,
	faFileDownload,
	farExclamationCircle,
	faFileExcel,
	faCog,
	faColumns,
	faMapMarked,
	faChartLine,
	faChartBar,
	faEdit,
	faUsers,
	faBellOn,
	faListAlt,
	faProjectDiagram,
	faChevronUp,
	faChevronDown,
	faPlusCircle,
	faMinusCircle,
	faBookmark,
	farBookmark,
	faWrench,
	faClipboardList,
	farExclamationTriangle,
	farPlusCircle,
	farMinusCircle,
	fasPhoneAlt,
	faFileAlt,
	faCaretUp,
	faCaretDown,
	faTrash
);

Vue.use(VModal);
Vue.use(vClickOutside);
Vue.use(Vuelidate);
Vue.use(VCalendar);
Vue.use(PerfectScrollbar);
Vue.use(VueCookies);
Vue.use(VueCompositionAPI);
Vue.use(FloatingVue);

const environment = process.env.VUE_APP_ENV;

// Google analytics
if (environment === "qa") {
	Vue.use(
		VueGtag,
		{
			config: {
				id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
				params: {
					cookie_flags: "Secure"
				}
			}
		},
		router
	);
}

// Sentry
if (environment === "qa" || environment === "uat") {
	Raven.config(process.env.VUE_APP_SENTRY_KEY)
		.addPlugin(RavenVue, Vue)
		.install();
}

Vue.component("BaseLayout", BaseLayout);
Vue.component("BasePagination", BasePagination);
Vue.component("BaseFormInput", BaseFormInput);
Vue.component("BaseFormInputLabel", BaseFormInputLabel);
Vue.component("BaseDropdown", BaseDropdown);
Vue.component("BaseDropdownLabel", BaseDropdownLabel);
Vue.component("BaseFormInputAutoComplete", BaseFormInputAutoComplete);
Vue.component("BaseButton", BaseButton);
Vue.component("BaseTag", BaseTag);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);
Vue.component("VMenu", Menu);

Vue.filter("dateTimeFormat", dateTimeFormat);
Vue.filter("dateFormat", dateFormat);
Vue.filter("capitalize", capitalize);
Vue.filter("currency", currency);
Vue.filter("fullCurrency", fullCurrency);
Vue.filter("twoDecimalComma", twoDecimalComma);
Vue.filter("friendlyNumber", friendlyNumber);
Vue.filter("friendlyNumberWithIntegerType", friendlyNumberWithIntegerType);
Vue.filter("allowDashString", allowDashString);
Vue.filter("percentageRoundUpDown", percentageRoundUpDown);
Vue.filter("nagativeCurrencyWithNoTApplicable", nagativeCurrencyWithNoTApplicable);

Vue.config.productionTip = false;
new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount("#app");

hack100vhForMobile();
